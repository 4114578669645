import React from 'react';
import { Helmet } from 'react-helmet';
import SEO from '../components/seo';
import styled from 'styled-components';
import { graphql } from 'gatsby';

const ReleaseForm = styled.iframe`
  position: absolute;
  inset: 0;
  border: 0;
`;

const ReleasePage = ({ pageContext, data }) => {
  const { formLink, label } = data.sanityReleaseForm;
  return (
    <>
      <SEO title={`Release Form | ${label}`} />
      <Helmet>
        <body style={{ overflow: `clip`, background: `var(--blue1)` }} />
      </Helmet>
      <ReleaseForm src={formLink} width='100%' height='100%' frameborder='0' />
    </>
  );
};

// GraphQL Query
export const releaseFormPageQuery = graphql`
  query ($slug: String) {
    sanityReleaseForm(slug: { current: { eq: $slug } }) {
      label
      slug {
        current
      }
      formLink
    }
  }
`;

export default ReleasePage;
